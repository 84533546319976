import jQuery from "jquery";

window.$ = window.jQuery = jQuery;
import 'slick-carousel';

(function ($) {
  $('.chronology-slider').slick({
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    easing: 'ease-in-out',
    arrows: false,
    dots: false,
    centerMode: true,
    fade: true,
    asNavFor: '.custom-dots',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 400,
          fade: false,
          adaptiveHeight: true,
        },
      },
    ],
  });
  $('.custom-dots').slick({
    infinite: false,
    speed: 400,
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: '.chronology-slider',
    arrows: false,
    dots: false,
    focusOnSelect: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });

  if (window.matchMedia('(min-width: 768px)').matches) {
    $('#chronology-slider').addClass('container');
  }
})(jQuery);

